import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@environment';
import { PresentationDto, PresentationViewModel, SharedPresentationDto, SharedPresentationViewModel } from '@features/models/presentation.model';
import { Result } from '@shared/models/result.model';
import { ENDPOINTS } from '@shared/utils/constants';

@Injectable({
  providedIn: 'root'
})
export class PresentationService {
  private url = `${environment.apis.linkroom}${ENDPOINTS.presentations}`;

  constructor(private http: HttpClient) {}

  get(id: string) {
    return this.http.get<Result<PresentationViewModel>>(`${this.url}/${id}`);
  }

  add(dto: PresentationDto) {
    return this.http.post<Result<string>>(`${this.url}`, dto);
  }

  archive(id: string) {
    return this.http.patch<Result>(`${this.url}/${id}`, null);
  }

  delete(id: string) {
    return this.http.delete<Result>(`${this.url}/${id}`);
  }

  getByRoomId(roomId: string) {
    return this.http.get<Result<PresentationViewModel[]>>(`${this.url}/list-by-room/${roomId}`);
  }

  list(organization: string) {
    let params = new HttpParams();
    params = params.set('organization', organization);

    return this.http.get<Result<PresentationViewModel[]>>(`${this.url}`, { params });
  }

  update(id: string, dto: Partial<PresentationDto>) {
    return this.http.put<Result>(`${this.url}/${id}`, dto);
  }

  updateCoordinates(id: string, dto: Partial<PresentationDto>) {
    return this.http.put<Result>(`${this.url}/${id}/coordinates`, dto);
  }

  addSharedPresentation(dto: SharedPresentationDto) {
    return this.http.post<Result<string>>(
      `${this.url}${ENDPOINTS.shared_presentations}`,
      dto
    );
  }

  listSharedPresentations(organization: string) {
    let params = new HttpParams();
    params = params.set('organization', organization);

    return this.http.get<Result<PresentationViewModel[]>>(
      `${this.url}${ENDPOINTS.shared_presentations}`,
      { params }
    );
  }

  getSharedPresentation(organization: string, presentation: string, recipient: string) {
    return this.http.get<Result<SharedPresentationViewModel>>(
      `${this.url}${ENDPOINTS.shared_presentations}/${organization}/${presentation}/${recipient}`
    );
  }

  listSharedPresentationsByOwner(organization: string, owner: string) {
    let params = new HttpParams();
    params = params.set('organization', organization);
    params = params.set('owner', owner);

    return this.http.get<Result<SharedPresentationViewModel[]>>(
      `${this.url}${ENDPOINTS.shared_presentations}/by-owner`,
      { params }
    );
  }

  deleteSharedPresentation(id: string) {
    return this.http.delete<Result>(
      `${this.url}${ENDPOINTS.shared_presentations}/${id}`
    );
  }
}
